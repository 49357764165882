import * as React from 'react';
import { graphql } from 'gatsby';
import LegalDocument from '../templates/LegalDocument';

interface Props {
  data: { markdownRemark: { html: string } };
}

const TosPage: React.StatelessComponent<Props> = ({ data }) => (
  <LegalDocument title='Terms of Service | VersionPress.com' data={data} />
);

export default TosPage;

export const query = graphql`
  query ToSQuery {
    markdownRemark(fileAbsolutePath: { regex: "/terms-of-service.md$/" }) {
      html
    }
  }
`;
